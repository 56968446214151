import {
  apiPost, apiGet, apiPatch, apiDelete,
} from '../utils/api';
import { API_URL } from '../constant';
import { createAuthenticatedUrl } from '../utils/video';
import { refreshToken } from './gcp';

export const getSessionApi = (memberId) => apiGet(`${API_URL}session/${memberId}`);

export const downloadSessionRecording = async (url) => {
  const refreshedToken = await refreshToken();
  const gcpToken = refreshedToken.data.access_token;
  const endPoint = createAuthenticatedUrl(url);
  return apiGet(
    endPoint,
    {},
    {
      Authorization: `Bearer ${gcpToken}`,
    },
    {
      responseType: 'blob',
    },
  );
};

export const getSessionRecordingURLForPlayer = async (url) => {
  const res = await apiGet(
      `${API_URL}session/signedUrl?url=${encodeURIComponent(url)}`,
  );
  if (res) return res;
  throw new Error('Invalid url');
};

export const addSessionNoteApi = (data) => apiPost(`${API_URL}session`, data);

export const deletedSessionNoteApi = (id) => apiDelete(`${API_URL}session/${id}`);

export const updateSessionNote = (id, data) => apiPatch(`${API_URL}session/${id}`, data);

export const getSessionNoteDetailsApi = (id) => apiGet(`${API_URL}session/details/${id}`);

export const patchSessionReadApi = (id) => apiPatch(`${API_URL}session/${id}/read`);
