import { CheckOutlined } from '@ant-design/icons';
import { List, Menu, message, Select, Space, Spin, Steps } from 'antd';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uploadFileAPI } from '../../../api/file';
import { listMemberApi } from '../../../api/member';
import { getUserApi, updateUsersApi } from '../../../api/user';
import { APP_URL_APPLE, APP_URL_GOOGLE } from '../../../constant';
import androidIcon from '../../../images/android_icon.png';
import appstoreIcon from '../../../images/appstore_icon.png';
import SiteLogo from '../../../images/autism_360_white_new_logo.png';
import backgroundImg from '../../../images/get-started-background.png';
import PrevButtonIcon from '../../../images/icons/Arrow_Prev.svg';
import NextButtonIcon from '../../../images/icons/nextIcon.svg';
import QRCodeImg from '../../../images/qr_code.png';
import { LoginContainer, LogoContainer } from '../../../pages/auth/styles';
import { setSelectedMemberData } from '../../../redux/actions/customerAction';
import { getCustomerApi } from '../../../redux/actions/notificationAction';
import { setCurrentLoginUser } from '../../../redux/actions/userMemberAction';
import WizardStep from '../../refactored/Modal/Wizard/WizardStep';
import OnboardingModal from '../OnboardingModal';

const { Option } = Select;
const { Step } = Steps;
const { SubMenu } = Menu;
const WelcomeScreen: React.FC<any> = (props) => {
  const {
    customerDataList,
    dispatch,
    loginUserData,
    currentLoginUser,
    customerSelectedMember,
    isMobile,
    handleOk,
    step
  } = props;
  const [current, setCurrent] = useState(step || 0);
  const [collapse, setCollapse] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [cam, setCam] = useState<any>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [errormessge, Seterrormessge] = useState<string>('');
  const webcamRef = React.useRef<any>(null);
  const [formUrl, setFormUrl] = useState('');
  const [showFormType, setFormType] = useState('');
  const capture = React.useCallback(() => {
    const imageSrc = cam;

    if (!imageSrc) {
      return;
    }

    const byteString = atob(imageSrc.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    uploadFile(new Blob([ab], { type: 'image/jpeg' }), 'newFile');
  }, [webcamRef, cam]);

  const [activeForm, setActiveForm] = useState({
    avatar: ''
  });
  const history = useHistory();
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user'
  };

  const formData =
    customerDataList.type != 'Customer'
      ? loginUserData && loginUserData.forms
      : customerDataList && customerDataList.forms;
  const ChildformData =
    customerSelectedMember && customerSelectedMember[0] && customerSelectedMember[0].forms;
  const childParseData = ChildformData && JSON.parse(ChildformData);
  const parentParseData = formData && JSON.parse(formData);

  useEffect(() => {
    // if (loginUserData && loginUserData.firstTimeLogin === true) {
    //   patchFirstLogin(loginUserData._id).then((res) => {});
    // }

    if (currentLoginUser._id) {
      getUserApi(currentLoginUser._id).then((res) => {
        const data = res;
        dispatch(getCustomerApi(data));
      });
    }
  }, []);

  const showModal = (key) => {
    setIsModalVisible(true);
    const keyVal = key.replace(/ /g, '');
    if (keyVal === 'ChildOnboardingProfile') {
      if (process.env.FORM_ENV === 'development') {
        setFormUrl('https://form.jotform.com/211082864962460');
      }
      if (process.env.FORM_ENV === 'test') {
        setFormUrl('https://form.jotform.com/210528488490058');
      }
      if (process.env.FORM_ENV === 'production') {
        setFormUrl('https://form.jotform.com/211083462625451');
      }
    } else if (keyVal === 'ParentOnboardingProfile') {
      if (process.env.FORM_ENV === 'development') {
        setFormUrl('https://form.jotform.com/211082955638461');
      }
      if (process.env.FORM_ENV === 'test') {
        setFormUrl('https://form.jotform.com/210528623971861');
      }
      if (process.env.FORM_ENV === 'production') {
        setFormUrl('https://form.jotform.com/211083629361453');
      }
    }
    setFormType(key);
  };

  const showViewModal = (data) => {
    setIsModalVisible(true);

    if (data.key === 'ChildOnboardingProfile') {
      if (process.env.FORM_ENV === 'development') {
        setFormUrl('https://form.jotform.com/211083342939456');
      }
      if (process.env.FORM_ENV === 'test') {
        setFormUrl('https://form.jotform.com/210541135931446');
      }
      if (process.env.FORM_ENV === 'production') {
        setFormUrl('https://form.jotform.com/211083888586469');
      }
    } else if (data.key === 'ParentOnboardingProfile') {
      if (process.env.FORM_ENV === 'development') {
        setFormUrl('https://form.jotform.com/211083802328449');
      }
      if (process.env.FORM_ENV === 'test') {
        setFormUrl('https://form.jotform.com/210541697731457');
      }
      if (process.env.FORM_ENV === 'production') {
        setFormUrl('https://form.jotform.com/211083644185454');
      }
    }

    setFormType(data.name);
  };
  const renderChildFormInput = () => {
    let childData = {};
    if (ChildformData === '' || ChildformData === undefined) {
      childData = {
        'yourName[first]':
          customerSelectedMember.length &&
          customerSelectedMember &&
          customerSelectedMember[0].firstName,
        'yourName[last]':
          customerSelectedMember.length &&
          customerSelectedMember &&
          customerSelectedMember[0].lastName,
        accountEmail: '',
        childsPreferred: customerSelectedMember.length
          ? `${customerSelectedMember[0].firstName} ${
              customerSelectedMember[0].lastName ? customerSelectedMember[0].lastName : ''
            }`
          : `${customerSelectedMember.firstName} ${
              customerSelectedMember.lastName ? customerSelectedMember.lastName : ''
            }`,
        member: customerSelectedMember.length
          ? `${customerSelectedMember[0].firstName} ${
              customerSelectedMember[0].lastName ? customerSelectedMember[0].lastName : ''
            }`
          : `${customerSelectedMember.firstName} ${
              customerSelectedMember.lastName ? customerSelectedMember.lastName : ''
            }`,
        formtype: showFormType.replace(/ /g, ''),
        userid: customerSelectedMember.length
          ? `${customerSelectedMember[0]._id}`
          : `${customerSelectedMember._id}`
      };
    } else {
      childData = {
        ...(childParseData && childParseData.ChildOnboardingProfile),
        'yourName[first]': childParseData && childParseData.ChildOnboardingProfile.yourName.first,
        'yourName[last]': childParseData && childParseData.ChildOnboardingProfile.yourName.last
      };
    }
    return childData;
  };
  // const gotoParentFormPage = () => {
  //   history.push("/dashboard/myforms/parent");
  // };
  // const gotoFormPage = () => {
  //   history.push("/dashboard/myforms/child");
  // };
  const convertToQueryString = (formInput) => {
    const encoded = `${qs.stringify(formInput)}`;
    const decoded = decodeURI(encoded);
    return decoded;
  };
  // const handleCancel = () => {
  //   setIsModalVisible(false);
  //   setFormUrl("");
  // };

  const handleCancel = () => {
    getUserApi(customerDataList._id).then((res) => {
      dispatch(getCustomerApi(res));
      dispatch(setCurrentLoginUser(res));
    });

    listMemberApi(customerSelectedMember[0]._id).then((res) => {
      const data = res;
      dispatch(setSelectedMemberData([data]));

      getUserApi(customerDataList._id).then((res) => {
        dispatch(getCustomerApi(res));
        dispatch(setCurrentLoginUser(res));
      });
    });
    setIsModalVisible(false);
    setFormUrl('');
  };
  const renderParentFormInput = () => {
    let parentData = {};
    if (formData === '' || formData === undefined) {
      parentData = {
        'yourName[first]':
          customerDataList.type != 'Customer'
            ? loginUserData.firstName
            : customerDataList.firstName,
        'yourName[last]':
          customerDataList.type != 'Customer' ? loginUserData.lastName : customerDataList.lastName,
        accountEmail:
          customerDataList.type != 'Customer' ? loginUserData.email : customerDataList.email,
        doYou3:
          customerDataList.type != 'Customer'
            ? `${loginUserData.firstName} ${loginUserData.lastName ? loginUserData.lastName : ''}`
            : customerDataList.length
              ? `${customerDataList[0].firstName} ${
                  customerDataList[0].lastName ? customerDataList[0].lastName : ''
                }`
              : `${customerDataList.firstName} ${
                  customerDataList.lastName ? customerDataList.lastName : ''
                }`,
        member: customerSelectedMember.length
          ? `${customerSelectedMember[0].firstName} ${
              customerSelectedMember[0].lastName ? customerSelectedMember[0].lastName : ''
            }`
          : `${customerSelectedMember.firstName} ${
              customerSelectedMember.lastName ? customerSelectedMember.lastName : ''
            }`,
        formtype: showFormType.replace(/ /g, ''),
        userid: customerDataList.type != 'Customer' ? loginUserData._id : customerDataList._id
      };
    } else {
      parentData = {
        ...(parentParseData && parentParseData.ParentOnboardingProfile),
        'yourName[first]': parentParseData
          ? parentParseData.ParentOnboardingProfile.yourName.first
          : customerDataList.firstName,
        'yourName[last]': parentParseData
          ? parentParseData.ParentOnboardingProfile.yourName.last
          : customerDataList.lastName
      };
    }
    return parentData;
  };
  const getCustomer = () => {
    getUserApi(loginUserData._id).then((res) => {
      dispatch(getCustomerApi(res));
      setActiveForm({
        avatar: res.avatar
      });
    });
  };
  const TimeZoneChange = (name: string, value: string) => {
    const payload = {
      [name]: value
    };
    updateUsersApi(loginUserData._id, payload).then((res) => {
      getCustomer();
    });
    setActiveForm({ ...activeForm, [name]: value });
  };

  const handleChange = (info, onlyFile = false) => {
    if (info.file.status != 'error') {
      return;
    }
    if (info.fileList.length === 0) {
      setShowUpload(false);
    } else {
      setShowUpload(true);
    }
    let fileList = [...info.fileList];
    fileList = fileList.slice(-2);
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      uploadFile(file.originFileObj, file.originFileObj.name);
      return file;
    });
  };

  const handleAndroid = () => {
    window.open(APP_URL_GOOGLE, '_tab');
  };

  const handleAppstore = () => {
    window.open(APP_URL_APPLE, '_tab');
  };

  const uploadFile = (fileObj, fileName) => {
    const formData = new FormData();
    formData.append('file', fileObj, fileName);
    if (formData) {
      uploadFileAPI(formData).then((res) => {
        const data = {
          avatar: res.public_url
        };
        setShowWebcam(false);
        message.success('Image uploaded successfully');
        // setActiveForm({
        //   avatar: res.public_url,
        // });
        updateUsersApi(loginUserData._id, data).then((res) => {
          getCustomer();
        });
      });
    }
    // return file;
  };

  const deleteFile = () => {
    const data = {
      avatar: ''
    };
    updateUsersApi(loginUserData._id, data).then((res) => {
      getCustomer();
    });
  };

  const dataHeader = {
    autorization: 'authorization-text'
  };
  const Leftsteps = [
    { id: 0, title: 'Welcome video', content: 'Click to play Welcome Video' },
    {
      id: 1,
      title: 'Tell us a bit about your child',
      content: customerSelectedMember.length
        ? `${customerSelectedMember[0].firstName} ${
            customerSelectedMember[0].lastName ? customerSelectedMember[0].lastName : ''
          }`
        : `${customerSelectedMember.firstName} ${
            customerSelectedMember.lastName ? customerSelectedMember.lastName : ''
          }`
    },
    {
      id: 2,
      title: 'Help us know you Better',
      content: `${customerDataList.firstName} ${
        customerDataList.lastName ? customerDataList.lastName : ''
      }`
    },
    {
      id: 3,
      title: "Let's get started",
      content: 'Your journey begins here..'
    }
  ];
  const steps = [
    {
      id: 0,
      title: 'Welcome video',
      content: (
        <div>
          <LoginContainer>
            <LogoContainer>
              <div className="Signin-container wizard-getting-started">
                <div
                  className={isMobile ? 'Background-rectangle-image wizard-rectangle-image' : ''}
                >
                  <div className={isMobile ? 'RectangleImage' : ''} />
                </div>

                <div className="sideContainer welcomeContainer">
                  <div className="sideMenu welcome-menu">
                    <div className={isMobile ? 'Signin-image welcome-text-container' : ''}>
                      <div className="welcome-user-text">
                        Yay,{' '}
                        {customerDataList.length
                          ? `${customerDataList[0].firstName} ${
                              customerDataList[0].lastName ? customerDataList[0].lastName : ''
                            }`
                          : `${customerDataList.firstName} ${
                              customerDataList.lastName ? customerDataList.lastName : ''
                            }`}
                      </div>
                      <div className="welcomeText">
                        <div className="SignInText wizard-text"> Welcome to </div>
                        <br />
                        <div className="SignInText wizard-text"> autism360 TM </div>
                        <br />
                        <div className="SignInText wizard-text"> portal</div>

                        <div className="credentialsText">
                          {' '}
                          Let’s start! It’s going to take only a few
                          <br />
                          minutes.{' '}
                        </div>
                      </div>
                    </div>
                    <div className="welcome-user-image">
                      <div className="resp-container">
                        <iframe
                          src="https://player.vimeo.com/video/547304290"
                          className="iframe_styling"
                          allow="fullscreen; picture-in-picture"
                          frameBorder="0"
                          allowFullScreen
                        />
                      </div>
                    </div>

                    <Spin spinning={isLoading} />
                  </div>
                </div>
              </div>
            </LogoContainer>
          </LoginContainer>
        </div>
      )
    },
    {
      id: 1,
      title: 'Last',
      content: (
        <WizardStep
          icon=""
          title="Tell us a bit about"
          // description={`${
          //   customerDataList
          //     ? customerDataList &&
          //       customerDataList.members &&
          //       customerDataList.members[0].firstName
          //     : customerDataList &&
          //       customerDataList.members &&
          //       customerDataList.members[0].firstName
          // } ${
          //   customerDataList
          //     ? customerDataList &&
          //       customerDataList.members &&
          //       customerDataList.members[0].lastName
          //     : customerDataList &&
          //       customerDataList.members &&
          //       customerDataList.members[0].lastName
          // }`}
          description={
            customerSelectedMember.length
              ? `${customerSelectedMember[0].firstName} ${
                  customerSelectedMember[0].lastName ? customerSelectedMember[0].lastName : ''
                }`
              : `${customerSelectedMember.firstName} ${
                  customerSelectedMember.lastName ? customerSelectedMember.lastName : ''
                }`
          }
          buttonProps={{
            disabled:
              // customerDataList &&
              // customerDataList.members &&
              // customerDataList.members[0].forms
              //   ? true
              //   : false,
              false,
            text:
              customerDataList && customerDataList.members && customerDataList.members[0].forms ? (
                <CheckOutlined className="tickIcon" />
              ) : (
                'Child Onboarding'
              ),
            onClick: () => {
              if (childParseData && childParseData.ChildOnboardingProfile) {
                const record = {
                  key: 'ChildOnboardingProfile',
                  name: 'Child Onboarding Profile',
                  type: 'Onboarding',
                  status:
                    childParseData && childParseData.ChildOnboardingProfile
                      ? 'submitted'
                      : 'Not submitted'
                };
                return showViewModal(record);
              }
              return showModal('Child Onboarding Profile');
            }
          }}
        />
      )
    },
    {
      id: 2,
      title: 'Help us know you Better',
      content: (
        <WizardStep
          icon=""
          title="Help us know you better"
          description={`${customerDataList.firstName} ${
            customerDataList.lastName ? customerDataList.lastName : ''
          }`}
          buttonProps={{
            disabled: false,
            //  customerDataList && customerDataList.forms ? true :
            text:
              customerDataList && customerDataList.forms ? (
                <CheckOutlined className="tickIcon" />
              ) : (
                'Carer Onboarding'
              ),
            onClick: () => {
              if (parentParseData && parentParseData.ParentOnboardingProfile) {
                const record = {
                  key: 'ParentOnboardingProfile',
                  name: 'Parent Onboarding Profile',
                  type: 'Onboarding',
                  status:
                    parentParseData && parentParseData.ParentOnboardingProfile
                      ? 'submitted'
                      : 'Not submitted'
                };
                return showViewModal(record);
              }
              return showModal('Parent Onboarding Profile');
            }
          }}
        />
      )
    },
    {
      id: 3,
      title: "Let's Get started",
      content: (
        <Space direction="vertical" size="middle">
          <List.Item>
            <List.Item.Meta title="Let's Get Started" description="Your journey begins here.." />
          </List.Item>
          <div className="getStartedContainer">
            <div className="relative w-fit">
              <img src={backgroundImg} width="100%" className="" />
              <div className="absolute top-[10%] left-1/4 w-1/2 h-3/5">
                <div className="relative w-full h-full">
                  <div className="absolute top-0 left-0">
                    <div className="relative">
                      <p className="text-[40px] font-black">Try Autism 360 for 7 days</p>
                      <p className="text-lg uppercase">
                        You can
                        <span className="font-bold">now</span> help your child
                        <br />
                        Express their needs, manage
                        <br />
                        Emotions & Become more independent
                      </p>
                      <div className="text-lg">
                        <span>◆ Netflix style courses</span>
                        <br />
                        <span>◆ 100s of strategy resources</span>
                        <br />
                        <span>◆ 1 on 1 Access to Expert Therapists</span>
                        <br />
                        <span>◆ Live expert-driven workshops</span>
                        <br />
                        <span>◆ Virtual therapy assist chat</span>
                        <br />
                        <span>◆ Parent Community</span>
                        <br />
                        <span>◆ and much more...</span>
                      </div>
                    </div>
                  </div>
                  <div className="absolute bottom-0 right-0 flex flex-col items-center gap-1">
                    <img src={QRCodeImg} width="200" />
                    <p className="text-lg text-center mb-1">
                      or simply go to
                      <br />
                      <a href="" className="text-blue-500">
                        https://autism360.com/access
                      </a>
                    </p>
                    <div className="flex gap-2">
                      <img src={androidIcon} onClick={handleAndroid} className="cursor-pointer" />
                      <img src={appstoreIcon} onClick={handleAppstore} className="cursor-pointer" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Space>
      )
    }
  ];
  const next = () => {
    const latestValue = current + 1;
    setCurrent(latestValue);
  };
  const prev = () => {
    const latestValue = current - 1;
    setCurrent(latestValue);
  };

  const toggleCollapsed = () => {
    setCollapse(!collapse);
  };
  const toggleMobileView = (data) => {
    setCurrent(data);
  };

  return (
    <div>
      <LoginContainer>
        <LogoContainer>
          {isMobile ? (
            <div className="Signin-container Wizard-container">
              <div className="sideContainer welcomeContainer right-mobile-view-Container">
                <div className="sideMenu welcome-menu">
                  {/* {Leftsteps.map((data, i) => (
                    data.id === current ? (
                      <div key={data.title}>{data.content}</div>
                    ) : null

                  ))} */}

                  {steps.map((data, i) =>
                    data.id === current ? (
                      <React.Fragment key={i}>
                        <div key={i}>{data.content}</div>
                      </React.Fragment>
                    ) : null
                  )}
                  <div className="welcome-user-image">
                    {current > 0 && (
                      <div className="click-prev-page" onClick={prev}>
                        <img src={PrevButtonIcon} />
                        Previous
                      </div>
                    )}
                    {current < steps.length - 1 && (
                      <div className="click-next-page" onClick={next}>
                        Next
                        <img src={NextButtonIcon} />
                      </div>
                    )}
                    {current > 2 && (
                      <div className="click-next-page" onClick={handleOk}>
                        Continue
                        <img src={NextButtonIcon} />
                      </div>
                    )}
                  </div>

                  <Spin spinning={isLoading} />
                </div>
              </div>
            </div>
          ) : (
            <div className="Signin-container Wizard-container">
              <div className="Background-rectangle-image wizard-autism-side-container progressContainer">
                <ul className="progress">
                  <img src={SiteLogo} alt="autism360™" className="autism360_white_logo_style" />
                  {Leftsteps.map((data, i) => (
                    <div
                      key={i}
                      className={
                        i === current
                          ? 'active-welcome-text-container'
                          : 'inactive-welcome-text-container'
                      }
                      onClick={() => setCurrent(data.id)}
                    >
                      <li
                        className={
                          i === current
                            ? 'progress__item  progress__item--active'
                            : 'progress__item  progress__item--inactive'
                        }
                        onClick={() => setCurrent(data.id)}
                      >
                        <p className="progress__title">{data.title}</p>
                        <span className="progress__info">{data.content}</span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>

              {/* RightSide */}
              <div className="sideContainer welcomeContainer">
                <div className="sideMenu welcome-menu">
                  {steps.map((data, i) =>
                    data.id === current ? <div key={data.title}>{data.content}</div> : null
                  )}
                  <div className="welcome-user-image">
                    {current > 0 && (
                      <div className="click-prev-page" onClick={prev}>
                        <img src={PrevButtonIcon} />
                        Previous
                      </div>
                    )}
                    {current < steps.length - 1 && (
                      <div className="click-next-page" onClick={next}>
                        Next
                        <img src={NextButtonIcon} />
                      </div>
                    )}
                    {current > 2 && (
                      <div className="click-next-page" onClick={handleOk}>
                        Continue
                        <img src={NextButtonIcon} />
                      </div>
                    )}
                  </div>

                  <Spin spinning={isLoading} />
                </div>
              </div>
            </div>
          )}

          <OnboardingModal
            handleCancel={handleCancel}
            visible={isModalVisible}
            onCancel={handleCancel}
            formUrl={formUrl}
            convertToQueryString={convertToQueryString}
            showFormType={showFormType}
            renderParentFormInput={renderParentFormInput}
            renderChildFormInput={renderChildFormInput}
            wizardFormStyle="onboarding-modal-wizard-container"
            wizardButtonStyle="wizard-button-style"
          />
        </LogoContainer>
      </LoginContainer>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    openWelcomeModal: state.customerRuducer.openWelcomeModal,
    customerDataList: state.notificationReducer.customerDataList,
    loginUserData: state.userMemberData.loginUserData,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    isMobile: state.trackReducer.isMobile,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(WelcomeScreen);
