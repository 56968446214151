import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

export const getHomePromptsAPI = (options?) => apiGet(`${API_URL}homePrompts`, options);

export const getHomePromptByIdAPI = (id) => apiGet(`${API_URL}homePrompts/${id}`);

export const postHomePromptAPI = (data) => apiPost(`${API_URL}homePrompts`, data);

export const patchHomePromptAPI = (id, data) => apiPatch(`${API_URL}homePrompts/${id}`, data);

export const deleteHomePromptAPI = (id) => apiDelete(`${API_URL}homePrompts`, { id });
