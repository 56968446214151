import { Checkbox, Col, Form, Input, Select, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { getCurrentPost, patchAnswer } from '../../api/post';
import { DrawerFooter, SubmitButton, UserLastRow } from '../common/styles';
import { showNotificationDrawer } from '../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;

interface PostFormProps {
  permission: 'view' | 'edit' | 'create';
  onClose: () => void;
  onSuccess: () => void;
  currentPostId: string;
}

const PostForm: React.FC<PostFormProps> = ({ permission, onClose, onSuccess, currentPostId }) => {
  const [form] = Form.useForm();
  const defaultData: PostFormState = {
    user: '',
    post: '',
    body: '',
    status: '',
    type: '',
    createdAt: moment()
  };
  const [activeForm, setActiveForm] = useState<PostFormState>(defaultData);
  const [currentPost, setCurrentPost] = useState<PostFormState>(defaultData);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

  useEffect(() => {
    if (currentPostId) {
      setIsloading(true);
      getCurrentPost(currentPostId).then((res) => {
        setIsloading(false);
        const { data } = res;
        const { user, post, createdAt, isDeleted } = data;
        const { body = '', status = 'Draft' } =
          data.interactions.filter((o) => o.type.toLowerCase() === 'response')[0] || {};
        const postData = {
          user,
          post,
          body,
          status,
          createdAt: moment(createdAt),
          isDeleted
        };
        const formData = { ...postData };
        setCurrentPost(postData);
        setActiveForm(formData);
        form.setFieldsValue(formData);
      });
    }
    if (permission === 'view') {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  }, [currentPostId, permission]);

  const onHandleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleInput = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const formStyle: CSSProperties = {};

  const onSubmit = () => {
    setIsloading(true);
    if (JSON.stringify(activeForm) === JSON.stringify(currentPost)) {
      showNotificationDrawer('Warning', 'Nothing changed');
      setIsloading(false);
      onClose();
    } else {
      patchAnswer(currentPostId, {
        body: activeForm.body,
        status: activeForm.status,
        isDeleted: activeForm.isDeleted
      })
        .then((res) => {
          setIsloading(false);
          form?.resetFields();
          showNotificationDrawer('Success', 'Answer for the post updated successfully!');
          onSuccess();
          onClose();
        })
        .catch((err) => {
          setIsloading(false);
          form?.resetFields();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    }
  };

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form author-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isloading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item label="Post" name="post">
              <Input.TextArea name="post" readOnly rows={6} />
            </Form.Item>
            <Form.Item
              label="Body"
              name="body"
              rules={[
                { required: true, message: 'Please input your body!' },
                { max: 2000, message: '2000 characters maximum!' }
              ]}
              className="no-boder time-zone-container-s"
            >
              <Input.TextArea
                name="body"
                onChange={(e) => onHandleInput(e, 'body')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
                readOnly={isReadOnly}
                rows={6}
              />
            </Form.Item>
            <Form.Item
              name="status"
              fieldKey="status"
              label="Status"
              style={{ borderBottom: '1px solid #E9E9E9' }}
              className="no-boder time-zone-container-s"
            >
              <Select onChange={(value) => onHandleChange('status', value)} disabled={isReadOnly}>
                <Option value="Draft">Draft</Option>
                <Option value="Published">Published</Option>
                <Option value="Archived">Archived</Option>
              </Select>
            </Form.Item>
            <Form.Item name="isDeleted">
              <Checkbox
                onChange={(e) => onHandleChange('isDeleted', e.target.checked)}
                checked={activeForm.isDeleted}
                disabled={isReadOnly}
              >
                Is Deleted
              </Checkbox>
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        {permission !== 'view' && (
          <Form.Item className="full-row no-border">
            <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
              Submit
            </SubmitButton>
          </Form.Item>
        )}
      </DrawerFooter>
    </Form>
  );
};

export default PostForm;
