import { EditOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Badge, Image, List, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getResourcesAPI, togglefavResourceAPI } from '../../api/resources';
import altThumbnailImage from '../../images/thumbnailAlt.png';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import {
  setFetchingFavResource,
  setIsOpenDrawer,
  setShowFormData
} from '../../redux/actions/formAction';
import { getResizedImageName } from '../../utils/file';
import { Mode, Role } from '../../utils/types/role';
import DeepLink from '../common/DeepLink';
import { EditViewIcon, StarDiv } from '../common/styles';
import { formattedDate } from '../common/utils';

const ResourcesTableComponent: React.FC<any> = (props) => {
  const {
    dispatch,
    resourcesList = [],
    selectedRowData,
    resourcesId,
    onChangeFavResource,
    checkboxChecked,
    currentLoginUser
  } = props;

  const [favResourceKey, setFavResourceKey] = useState<any>([]);
  const fetchResouceList = useCallback(
    (searchTerm: string = '', isActive) => {
      const data = {
        search: searchTerm,
        type: '',
        objective: '',
        owner: '',
        isFavourites: isActive
      };
      dispatch(setCustomerLoading(false));
      return getResourcesAPI(data, '');
    },
    [dispatch]
  );

  const StatusColorSwitch = (status) => {
    switch (status) {
      case 'Pending':
        return 'pending-color';
      case 'Active':
        return 'active-color';
      case 'Reject':
        return 'reject-color';
      case 'Approved':
        return 'approved-color';
    }
  };

  useEffect(() => {
    fetchResouceList('', true).then((res) => {
      const { data } = res;
      const favList = data && data.map((favItem) => favItem._id);
      setFavResourceKey(favList);
      dispatch(setFetchingFavResource(data));
      dispatch(setCustomerLoading(false));
      if (resourcesId) {
        const resourceData = {
          _id: resourcesId
        };
        openDrawar(resourceData, Mode.View);
      }
    });
  }, [dispatch, fetchResouceList]); // eslint-disable-line react-hooks/exhaustive-deps

  const openDrawar = (data, mode) => {
    const createFormData = {
      id: data._id,
      userType: currentLoginUser.type,
      formMode: mode,
      formLabel: 'Resources'
    };

    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const onSelectRow = (record) => {
    if (record && record._id) {
      dispatch(setCustomerLoading(true));
      togglefavResourceAPI(record._id).then((res) => {
        const existingId = favResourceKey.filter((fav) => fav === record._id);
        if (existingId.length) {
          const favdata = favResourceKey.filter((fav) => fav !== record._id);
          setFavResourceKey(favdata);
          onChangeFavResource(checkboxChecked);
        } else {
          setFavResourceKey(favResourceKey.concat(record._id));
        }
        dispatch(setCustomerLoading(false));
      });
    }
  };

  const defaultSelected = (id) => {
    const activeList = favResourceKey.filter((fav) => fav === id);
    if (activeList.length && activeList[0] === id) {
      return 'active';
    }
    return '';
  };

  const modifyData =
    resourcesList.map((itemData) => {
      if (itemData.isDeleted !== true) {
        return { ...itemData, key: itemData._id };
      }
      return [];
    }) || [];

  const dataSource =
    currentLoginUser.type === Role.Business || currentLoginUser.type === Role.Admin
      ? modifyData
      : modifyData.filter((item) => !(item.status === 'Reject'));
  return (
    <List
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(item: any) => {
        const thumbnail = (
          <div>
            {item?.thumbnail ? (
              <Image
                onClick={(e) => e.stopPropagation()}
                width="150px"
                height="100px"
                style={{ objectFit: 'cover' }}
                src={getResizedImageName(item.thumbnail, '800x800')}
              />
            ) : (
              <img
                style={{ cursor: 'auto' }}
                onClick={(e) => e.stopPropagation()}
                width="150px"
                height="100px"
                src={altThumbnailImage}
              />
            )}
          </div>
        );
        return (
          <List.Item
            onClick={(e) => {
              e.stopPropagation(), openDrawar(item, Mode.View);
            }}
            className="resource-list-item"
            actions={[
              <DeepLink
                type="internal"
                deepLink={item.internalDeepLink}
                hidden={item.status !== 'Active'}
              />,
              <Space size="middle">
                <EditViewIcon className="autismag-table-action">
                  {currentLoginUser.type === Role.Business ||
                  currentLoginUser.type === Role.Admin ? (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        openDrawar(item, Mode.Edit);
                      }}
                    >
                      <EditOutlined key="edit" />
                    </span>
                  ) : null}
                </EditViewIcon>
              </Space>
            ]}
          >
            <List.Item.Meta
              avatar={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <div
                      style={{ width: "4rem", padding: ".5rem" }}
                    >
                      <Badge
                        count={item.order}
                        style={{ background: "#52c41a" }}
                      />
                    </div> */}
                  <div style={{ margin: 'auto 25px' }}>
                    <StarDiv>
                      {defaultSelected(item._id) === 'active' ? (
                        <StarFilled
                          onClick={(e) => {
                            e.stopPropagation(), onSelectRow(item);
                          }}
                          className={`icon ${defaultSelected(item._id)}`}
                        />
                      ) : (
                        <StarOutlined
                          onClick={(e) => {
                            e.stopPropagation(), onSelectRow(item);
                          }}
                          className={`icon ${defaultSelected(item._id)}`}
                        />
                      )}
                    </StarDiv>
                  </div>

                  {item.order ? (
                    <Badge.Ribbon text={item.order} placement="start" color="purple">
                      {thumbnail}
                    </Badge.Ribbon>
                  ) : (
                    thumbnail
                  )}
                </div>
              }
              title={item.name}
              description={
                <div className="resource-list-description">
                  <span>Resource Type :</span> {item.type?.name}{' '}
                  <span>
                    {' '}
                    <span style={{ marginRight: '10px' }}> </span> Added At :
                  </span>{' '}
                  {formattedDate(item.createdAt)}
                </div>
              }
            />
            <div style={{ flex: 0.18 }}>
              <DeepLink
                type="external"
                deepLink={item.externalDeepLink}
                hidden={item.status !== 'Active'}
              />
            </div>
            <div style={{ flex: 0.14 }}>
              <div className={item.Premium ? 'premium-resource-color' : 'free-resource-color'}>
                {' '}
                {item.Premium ? 'Premium' : 'Free'}
              </div>
            </div>
            <div style={{ flex: 0.14 }}>
              <div className={StatusColorSwitch(item.status)}>{item.status}</div>
            </div>
          </List.Item>
        );
      }}
    />
  );
};

function mapStateToProps(state: any) {
  return {
    showFormData: state.formReducer.showFormData,
    isOpenDrawer: state.formReducer.isOpenDrawer,
    resourcesList: state.formReducer.resourcesList,
    favResourcesList: state.formReducer.favResourcesList,
    resourceListMode: state.formReducer.resourceListMode,
    selectedRowData: state.formReducer.selectedRowData,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(ResourcesTableComponent);
