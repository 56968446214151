import { PlusOutlined } from '@ant-design/icons';
import { Pagination, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHomePromptsAPI } from '../../api/homePrompt';
import { userMemberDataType } from '../../constant';
import { UserContainer } from '../../pages/dashboard/styles';
import { setIsOpenDrawer, setShowFormData } from '../../redux/actions/formAction';
import { RootState } from '../../redux/store';
import PRIVILEGES from '../../utils/privilege';
import { Mode } from '../../utils/types/role';
import { AddButton } from '../common/styles';
import HomePromptModal from './HomePromptModal';
import HomePromptsTable from './HomePromptsTable';

interface Pagination {
  page: number;
  pageSize?: number;
  total: number;
}

const HomePromptsComponent = () => {
  const dispatch = useDispatch();

  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );
  const canCreate = PRIVILEGES.HomePrompts.Create?.includes(currentLoginUser.type);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: 10,
    total: 0
  });

  const fetchHomePrompts = async (pagination?) => {
    try {
      const res = await getHomePromptsAPI(pagination);
      setData(res.data);

      setPagination((prev: Pagination) => ({ ...prev, total: res.count }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      await fetchHomePrompts(pagination);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    fetchHomePrompts(pagination);
  }, [pagination.page]);

  const onPageChange = (page: number, pageSize?: number) => {
    setPagination((prev: Pagination) => ({ ...prev, page, pageSize }));
  };

  const openModal = (id: string = '', mode: Mode) => {
    const formData = {
      id,
      formMode: mode,
      formLabel: 'Home Prompt'
    };
    dispatch(setShowFormData(formData));
    dispatch(setIsOpenDrawer(true));
  };

  return (
    <Spin spinning={loading}>
      <UserContainer className="items-center">
        {canCreate && (
          <AddButton type="primary" onClick={() => openModal(undefined, Mode.Create)}>
            <PlusOutlined />
          </AddButton>
        )}
        <HomePromptModal onSubmit={fetchHomePrompts} pagination={pagination} />
      </UserContainer>

      <div className="site-layout-background">
        <HomePromptsTable className="resources-table" onOpenModal={openModal} data={data} />
        <Pagination
          current={pagination.page}
          total={pagination.total}
          pageSize={pagination.pageSize}
          onChange={onPageChange}
          className="my-pagination"
        />
      </div>
    </Spin>
  );
};

export default HomePromptsComponent;
