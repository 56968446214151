import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Spin, TablePaginationConfig } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { searchPostApi } from '../api/post';
import { CustomerContainer } from './common/styles';
import { SearchContainer } from './ListingComponent/styles';
import PostTableComponent from './TableData/PostTableComponent';

export interface PostComponentProps {}

const PostComponent: React.FC<any> = (props: PostComponentProps) => {
  const [isloading, setLoading] = useState<boolean>(false);
  const [postList, setPostList] = useState<Post[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10
  });

  const fetchData = async (_pagination = pagination) => {
    const { current = 1, pageSize = 10 } = _pagination;
    setLoading(true);
    try {
      const options = {
        page: current,
        limit: pageSize,
        search: searchTerm
      };
      const response = await searchPostApi(options);
      setPostList(response?.data?.docs);
      setPagination((prev) => ({ ...prev, total: response?.data?.totalCount }));
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };
  const onTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
    fetchData(pagination);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, current: 1 });
        fetchData({ ...pagination, current: 1 });
      }
    },
    [searchTerm]
  );

  return (
    <div>
      <Spin spinning={isloading}>
        <div className="site-layout-background" style={{ marginTop: '30px' }}>
          <SearchContainer className="search-box search-video-box">
            <Button
              onClick={() => (searchTerm !== '' ? fetchData() : null)}
              className="SearchButton"
            >
              <SearchOutlined />
            </Button>
            <Input
              onKeyPress={handleKeyPress}
              name="search"
              value={searchTerm}
              onChange={handleChange}
              placeholder="Search Post"
            />
          </SearchContainer>
          <div className="site-card-wrapper">
            <CustomerContainer>
              <PostTableComponent
                className="resources-table "
                postData={postList}
                pagination={pagination}
                onTableChange={onTableChange}
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default PostComponent;
