export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_AUTHOR_LIST = 'SET_AUTHOR_LIST';
export const SET_AUTHOR_PAGE_COUNT = 'SET_AUTHOR_PAGE_COUNT';
export const SET_AUTHOR_CURRENT_PAGE = 'SET_AUTHOR_CURRENT_PAGE';
export const SET_VIDEO_CURRENT_PAGE = 'SET_VIDEO_CURRENT_PAGE';
export const SET_VIDEO_PAGE_COUNT = 'SET_VIDEO_PAGE_COUNT';
export const SET_VIDEO_LIST = 'SET_VIDEO_LIST';
export const SET_MEMBER_LIST = 'SET_MEMBER_LIST';
export const SET_MEMBER_DATA = 'SET_MEMBER_DATA';
export const SET_MEMBER_LOADING = 'SET_MEMBER_LOADING';
export const SET_TOTAL_MEMBER_COUNT = 'SET_TOTAL_MEMBER_COUNT';
export const SET_MEMBER_CURRENT_PAGE = 'SET_MEMBER_CURRENT_PAGE';
export const SET_TRACKING_CODE = 'SET_TRACKING_CODE';
export const SET_TRACKING_LOADING = 'SET_TRACKING_LOADING';
export const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO';
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';
export const SET_CUSTOMER_LOADING = 'SET_CUSTOMER_LOADING';
export const SET_SELECTED_MEMBER_DATA = 'SET_SELECTED_MEMBER_DATA';
export const SET_CUSTOMER_FORM_DETAILS = 'SET_CUSTOMER_FORM_DETAILS';
export const SET_BANNER_HEADER_LABEL = 'SET_BANNER_HEADER_LABEL';
export const SET_CURRENT_LOGIN_USER = 'SET_CURRENT_LOGIN_USER';
export const SET_LOGIN_USER_DATA = 'SET_LOGIN_USER_DATA';
export const SET_CUSTOMER_ACCESS_DATA = 'SET_CUSTOMER_ACCESS_DATA';
export const SET_MEMBER_GOALS = 'SET_MEMBER_GOALS';
export const SET_SKILL_LIST = 'SET_SKILL_LIST';
export const SET_COMPLEXITY_LIST = 'SET_COMPLEXITY_LIST';
export const SET_ASSIGNEE_LIST = 'SET_ASSIGNEE_LIST';
export const SET_TARGET_SKILL_LIST = 'SET_TARGET_SKILL_LIST';
export const SET_FILE_LINK_LIST = 'SET_FILE_LINK_LIST';
export const SET_CONTENT_LINK_LIST = 'SET_CONTENT_LINK_LIST';
export const SET_ADD_TARGET_ACHIEVED = 'SET_ADD_TARGET_ACHIEVED';
export const SET_SESSION_DATA = 'SET_SESSION_DATA';
export const SET_SHOW_FORM_DATA = 'SET_SHOW_FORM_DATA';
export const SET_IS_OPEN_DRAWER = 'SET_IS_OPEN_DRAWER';
export const SET_FETCH_ALL_RESOURCES = 'SET_FETCH_ALL_RESOURCES';
export const SET_FETCH_FAV_RESOURCES = 'SET_FETCH_FAV_RESOURCES';
export const SET_OBJECTIVE_LIST = 'SET_OBJECTIVE_LIST';
export const SET_RESOURCE_TYPES = 'SET_RESOURCE_TYPES';
export const SET_RESOURCE_LIST_MODE = 'SET_RESOURCE_LIST_MODE';
export const SET_SELECTED_ROW_DATA = 'SET_SELECTED_ROW_DATA';
export const SET_RESOURCES_RELATED_TASKS = 'SET_RESOURCES_RELATED_TASKS';
export const SET_USER_MEMBER_RESOURCES = 'SET_USER_MEMBER_RESOURCES';
export const SET_USER_LOCAL_DATA = 'SET_USER_LOCAL_DATA';
export const SET_SUB_MENU_INDEX = 'SET_SUB_MENU_INDEX';
export const SET_MENU_INDEX = 'SET_MENU_INDEX';
export const SET_WELCOME_MODAL = 'SET_WELCOME_MODAL';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_NOTIFICATION_ALERT = 'SET_NOTIFICATION_ALERT';
export const ADD_NOTIFICATION_ALERT = 'ADD_NOTIFICATION_ALERT';
export const GET_NOTIFICATION_ALERT = 'GET_NOTIFICATION_ALERT';
export const PUT_NOTIFICATION_ALERT = 'PUT_NOTIFICATION_ALERT';
export const NOTIFICATION_VISIBLE = 'NOTIFICATION_VISIBLE';
export const WIZARD_VISIBLE = 'WIZARD_VISIBLE';
export const SESSION_VISIBLE = 'SESSION_VISIBLE';
export const GET_CUSTOMER_API = 'GET_CUSTOMER_API';
export const SET_VISIBLE_POPUP = 'SET_VISIBLE_POPUP';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const REFRESH_SESSION_NOTES = 'REFRESH_SESSION_NOTES';
export const SET_FILE_LOADING = 'SET_FILE_LOADING';
export const SET_WORKSHOP_CURRENT_PAGE = 'SET_WORKSHOP_CURRENT_PAGE';
export const SET_WORKSHOP_LIST = 'SET_WORKSHOP_LIST';
export const SET_WORKSHOP_PAGE_COUNT = 'SET_WORKSHOP_PAGE_COUNT';
export const SET_COURSE_LIST = 'SET_COURSE_LIST';
export const SET_COURSE_PAGE_COUNT = 'SET_COURSE_PAGE_COUNT';
export const SET_COURSE_CURRENT_PAGE = 'SET_COURSE_CURRENT_PAGE';
export const SET_RESOURCE_OBJECTIVE_LIST = 'SET_RESOURCE_OBJECTIVE_LIST';
export const SET_RESOURCE_OBJECTIVE_PAGINATION = 'SET_RESOURCE_OBJECTIVE_PAGINATION';
export const SET_VIDEO_CATEGORY_LIST = 'SET_VIDEO_CATEGORY_LIST';
export const SET_VIDEO_CATEGORY_PAGINATION = 'SET_VIDEO_CATEGORY_PAGINATION';
