import { DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, Input, Select, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import {
  addResourceAPI,
  deleteResourceAPI,
  getResourceDetailsAPI,
  getResourceObjective,
  getResourcesAPI,
  getResourceType,
  updateResourceAPI
} from '../../api/resources';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import { setFetchingResource } from '../../redux/actions/formAction';
import useVideoPlayerModal from '../../utils/hooks/useVideoPlayerModal';
import { Mode, Role } from '../../utils/types/role';
import FileUploader from '../common/FileUploader';
import { DeleteTabDiv, DrawerFooter, SubmitButton, UserLastRow } from '../common/styles';
import { showNotificationDrawer } from '../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};
const { Option } = Select;
const checkBoxLabel = 'Is this document created by External company, agency';

interface ResourcesFormProps {
  userRole: Role;
  close: any;
  cancel: any;
  resourceID?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  showFormData: any;
  state: any;
  customerDataList: any;
  fileLoading?: boolean;
  pagination?: any;
  filterForm?: any;
}
interface IResourceFormData {
  _id?: string;
  name?: string;
  type?: string;
  isDeleted?: boolean;
  status?: string;
  Premium?: boolean;
  createdByAgency?: boolean;
  resourseGuideType?: string;
  objective?: any[];
  rejectReason?: string;
  description?: string;
  file?: any;
  fileName?: string;
  document?: any;
  thumbnail?: string;
  videoPreview?: string;
  documentName?: string;
  order?: number;
}
const ResourcesForm: React.FC<ResourcesFormProps> = (props) => {
  const [form] = Form.useForm();
  const {
    dispatch,
    resourceID,
    showFormData,
    visible,
    state,
    customerDataList,
    fileLoading,
    pagination,
    filterForm
  } = props;
  const statusTypes = customerDataList?.businessUserType?.includes('Resource Manager')
    ? ['Pending', 'Reject', 'Approved']
    : customerDataList.resourceAdmin
      ? ['Active', 'Pending', 'Reject']
      : ['Pending', 'Reject'];

  const [activeForm, setActiveForm] = useState<IResourceFormData>({
    Premium: false,
    createdByAgency: false,
    resourseGuideType: 'None',
    file: '',
    status:
      props.userRole === Role.Admin ||
      customerDataList?.businessUserType?.includes('Resource Manager')
        ? 'Active'
        : 'Pending'
  });
  const [fileError, setFileError] = useState<string>('');
  const [objectiveList, setObjectiveList] = useState<any>([]);
  const [resourceTypes, setResourceTypes] = useState<any>([]);
  const [isloading, setisloading] = useState<boolean>(false);
  const { component: videoPlayerComponent, open: openVideoPlayer } = useVideoPlayerModal(
    activeForm.document
  );

  const fetchResouceList = useCallback(
    (value: any = {}, isActive, option) => {
      const data = {
        search: value.search ? value.search : '',
        type: value.type && value.type.length ? value.type : '',
        objective: value.objective && value.objective.length ? value.objective : '',
        owner: value.owner && value.owner.length ? value.owner : '',
        isFavourites: value.isFavourites
      };
      const options = {
        skip: option.current * option.pageSize - option.pageSize,
        limit: option.pageSize
      };
      dispatch(setCustomerLoading(true));
      return getResourcesAPI(data, options);
    },
    [dispatch]
  );

  useEffect(() => {
    const activeFormLength = Object.keys(activeForm).length;

    if (!activeFormLength) {
      if (showFormData.formMode === Mode.Create) {
        const createData = {
          objective: [],
          createdByAgency: false,
          description: '',
          file: '',
          name: '',
          status:
            props.userRole === Role.Admin ||
            customerDataList?.businessUserType?.includes('Resource Manager')
              ? 'Active'
              : 'Pending',
          type: '',
          resourseGuideType: 'None'
        };
        setActiveForm(createData);
        form.setFieldsValue(createData);
      }
    }

    if (resourceID && visible) {
      setisloading(true);
      getResourceDetailsAPI(resourceID).then((res) => {
        const { data } = res;
        const stateData: IResourceFormData = {
          createdByAgency: data.createdByAgency,
          description: data.description,
          objective: data.objective && data.objective.map((item) => item._id),
          file: data.file,
          fileName: data.fileName,
          name: data.name,
          status: data && data.status,
          type: data.type?._id,
          _id: data._id,
          isDeleted: data.isDeleted,
          rejectReason: data.rejectReason,
          resourseGuideType: data.resourseGuideType,
          thumbnail: data.thumbnail,
          videoPreview: data.videoPreview,
          document: data.document,
          documentName: data.documentName,
          Premium: data.Premium,
          order: data.order
        };
        setActiveForm(stateData);
        form.setFieldsValue(stateData);
        setisloading(false);
      });
    }

    getResourceObjective().then((res) => {
      if (res && res.data) {
        setObjectiveList(res.data);
      }
    });
    getResourceType().then((res) => {
      if (res && res.data) {
        setResourceTypes(res.data);
      }
    });
  }, [form, resourceID, showFormData, visible, props.userRole]);

  const onReset = () => {
    form.resetFields();
    setActiveForm({});
    props.close();
    props.cancel();
  };

  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onSubmit = () => {
    setisloading(true);
    const updateData = {
      ...activeForm,
      rejectReason: activeForm.status != 'Reject' ? '' : activeForm.rejectReason
    };
    if (resourceID) {
      updateResourceAPI(resourceID, updateData)
        .then((res) => {
          showNotificationDrawer('Success', 'Resource updated successfully!');

          fetchResouceList(filterForm, false, pagination).then((res) => {
            const { data } = res;
            dispatch(setFetchingResource(data));
            dispatch(setCustomerLoading(false));
            setisloading(false);
            setActiveForm({});
            form?.resetFields();
            props.cancel();
            onReset();
          });
        })
        .catch((err) => {
          setisloading(false);
          onReset();
          dispatch(setCustomerLoading(false));
          showNotificationDrawer('Error', err.response.data.message);
        });
      setActiveForm({});
      form?.resetFields();
      props.cancel();
    }
    if (!resourceID) {
      if (!activeForm.file) {
        setFileError('Please attach your file');
        return;
      }
      setFileError('');

      const createData = {
        ...activeForm
      };

      // if(props.userRole !== Role.Admin){
      //     delete createData.status;
      // }

      addResourceAPI(createData)
        .then((res) => {
          onReset();
          showNotificationDrawer('Success', 'Resource added successfully!');
          fetchResouceList(filterForm, false, pagination).then((res) => {
            const { data } = res;
            dispatch(setFetchingResource(data));
            dispatch(setCustomerLoading(false));
            setisloading(false);
          });
        })
        .catch((err) => {
          setisloading(false);
          onReset();
          dispatch(setCustomerLoading(false));
          showNotificationDrawer('Error', err.response.data.message);
        });
    }
  };

  const deleteResource = (id) => {
    if (id) {
      dispatch(setCustomerLoading(true));
      deleteResourceAPI(id)
        .then((res) => {
          onReset();
          dispatch(setCustomerLoading(false));
          showNotificationDrawer('Success', 'Resource delete successfully!');
          fetchResouceList(filterForm, false, pagination).then((res) => {
            const { data } = res;
            dispatch(setFetchingResource(data));
            dispatch(setCustomerLoading(false));
          });
        })
        .catch((err) => {
          onReset();
          dispatch(setCustomerLoading(false));
          showNotificationDrawer('Error', err.response.data.message);
        });
      setActiveForm({});
      form?.resetFields();
      props.cancel();
    }
  };

  const onChangeFile = (url, name) => {
    setActiveForm({ ...activeForm, file: url, fileName: name });
  };

  const onChangeDocument = (url, name) => {
    setActiveForm({ ...activeForm, document: url, documentName: name });
  };

  const onChangethumbnialFile = (url, name) => {
    setActiveForm({ ...activeForm, thumbnail: url });
  };
  const onChangeVideoPreviewFile = (url, name) => {
    setActiveForm({ ...activeForm, videoPreview: url });
  };

  const onCheck = (e: any) => {
    const { checked } = e.target;
    setActiveForm({ ...activeForm, createdByAgency: checked });
  };

  const onPremiumCheck = (e: any) => {
    const { checked } = e.target;
    setActiveForm({ ...activeForm, Premium: checked });
  };

  const handleLinkClick = (text) => {
    if (props.viewMode === 'View' && text && text.startsWith('https://')) {
      openVideoPlayer();
    }
  };

  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isloading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Resource Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Resource Name!',
                  whitespace: true
                }
              ]}
            >
              <Input
                name="name"
                onChange={(e) => onHandleChange(e, 'name')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Resource Type"
              name="type"
              rules={[{ required: true, message: 'Please input resource Type!' }]}
              className="no-boder time-zone-container-s"
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={(value) => handleChange('type', value)}
                placeholder="Please select type"
              >
                {resourceTypes.map((data, index: number) => (
                  <Option key={index} value={data._id}>
                    {data.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* //Premium */}
            {props.viewMode != 'View' ? (
              <Form.Item
                name="Premium"
                label=""
                className="no-boder full-row"
                rules={[{ required: false, message: 'Please check as the box!' }]}
              >
                <Checkbox
                  className="Check-goal-as-primary"
                  onChange={onPremiumCheck}
                  checked={activeForm.Premium}
                >
                  Premium
                </Checkbox>
              </Form.Item>
            ) : null}
            <Form.Item
              label="Resource Categories"
              name="objective"
              rules={[{ required: true, message: 'Please input your objective!' }]}
            >
              {objectiveList && objectiveList.length ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  mode="multiple"
                  onChange={(value) => handleChange('objective', value)}
                  placeholder="Please select objective"
                  filterOption={(input, option) => {
                    if (option) {
                      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return true;
                  }}
                >
                  {objectiveList.map((data, index: number) => (
                    <Option key={index} value={data._id}>
                      {data.name}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select />
              )}
            </Form.Item>

            <Form.Item
              name="createdByAgency"
              label=""
              className="no-boder full-row"
              rules={[{ required: false, message: 'Please check as the box!' }]}
            >
              <Checkbox
                className="Check-goal-as-primary"
                onChange={onCheck}
                checked={activeForm.createdByAgency}
              >
                {checkBoxLabel}
              </Checkbox>
            </Form.Item>
            <Form.Item name="order" label="Resource Order" rules={[{ required: false }]}>
              <Input
                name="order"
                type="number"
                onChange={(e) => onHandleChange(e, 'order')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Resource Description"
              name="description"
              className="no-boder"
              rules={[
                {
                  required: true,
                  message: 'Please input your description!',
                  whitespace: true
                }
              ]}
            >
              <Input.TextArea
                name="description"
                onChange={(e) => onHandleChange(e, 'description')}
              />
            </Form.Item>

            <Form.Item
              label="Upload Resource"
              name="file"
              className="no-boder"
              rules={[{ required: true, message: 'Please upload resource' }]}
            >
              <FileUploader
                mode={props.viewMode}
                onChange={onChangeFile}
                avatarImage={activeForm?.file}
                fileName={activeForm?.fileName}
                errorMessage={fileError}
                downloadFile
                type="resources"
              />
            </Form.Item>

            <Form.Item label="Resource Guide Type" name="resourseGuideType">
              <Select
                defaultValue="None"
                disabled={!!((activeForm.document && props.viewMode != 'View') || fileLoading)}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={(value) => handleChange('resourseGuideType', value)}
              >
                <Option key={1} value="Video">
                  Video
                </Option>
                <Option key={2} value="Tip Sheet">
                  Tip Sheet
                </Option>
                <Option key={3} value="None">
                  None
                </Option>
              </Select>
            </Form.Item>

            {activeForm.resourseGuideType && activeForm.resourseGuideType != 'None' ? (
              <Form.Item
                label={`${
                  form.getFieldValue('resourseGuideType') === 'Video'
                    ? 'Video Link'
                    : 'Upload Document'
                }`}
                name="document"
                className="no-boder"
                rules={[{ required: true, message: 'field is required' }]}
                style={{ pointerEvents: 'auto' }}
              >
                {form.getFieldValue('resourseGuideType') === 'Video' ? (
                  props.viewMode === 'View' ? (
                    <span
                      onClick={() => handleLinkClick(activeForm.document)}
                      style={{
                        pointerEvents: 'auto',
                        cursor: 'pointer',
                        color: '#2a8ff7'
                      }}
                    >
                      {activeForm.document}
                    </span>
                  ) : (
                    <Input
                      name="document"
                      placeholder="https://example.com"
                      pattern="https://.*"
                      onChange={(e) => onHandleChange(e, 'document')}
                    />
                  )
                ) : (
                  // <FileUploader  onlyVideoAccept={true} mode={props.viewMode} onChange={onChangeFile} avatarImage={activeForm?.file} fileName={activeForm?.fileName} errorMessage={fileError} downloadFile={true} type="resources"/>
                  <FileUploader
                    onlyFileAccept
                    mode={props.viewMode}
                    onChange={onChangeDocument}
                    avatarImage={activeForm?.document}
                    fileName={activeForm?.documentName}
                    errorMessage={fileError}
                    downloadFile
                    type="resources"
                  />
                )}
              </Form.Item>
            ) : null}

            <Form.Item
              label="Upload Thumbnail"
              name="thumbnail"
              className="no-boder"
              // rules={[{required:true}]}
            >
              <FileUploader
                onlyImageAccept
                limit={2097152}
                mode={props.viewMode}
                onChange={onChangethumbnialFile}
                avatarImage={activeForm?.thumbnail}
                errorMessage={fileError}
                downloadFile
                resize
              />
            </Form.Item>
            {
              /**
               * Video Preview
               */
              activeForm?.resourseGuideType === 'Video' && (
                <Form.Item
                  label="Upload Video Preview"
                  name="videoPreview"
                  className="no-boder"
                  // rules={[{required:true}]}
                >
                  <FileUploader
                    onlyImageAccept
                    limit={2097152}
                    mode={props.viewMode}
                    onChange={onChangeVideoPreviewFile}
                    avatarImage={activeForm?.videoPreview}
                    errorMessage={fileError}
                    downloadFile
                  />
                </Form.Item>
              )
            }
            {props.userRole === Role.Admin ||
            customerDataList?.businessUserType?.includes('Resource Manager') ? (
              <>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please select status!' }]}
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    defaultValue="Active"
                    onChange={(value) => handleChange('status', value)}
                    placeholder="Please select status"
                  >
                    {statusTypes.map((data, index: number) => (
                      <Option key={index} value={data}>
                        {data}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {activeForm.status === 'Reject' ? (
                  <Form.Item
                    label="Reject Reason"
                    name="rejectReason"
                    className="no-boder"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your reject reason!',
                        whitespace: true
                      }
                    ]}
                  >
                    <Input.TextArea
                      name="rejectReason"
                      onChange={(e) => onHandleChange(e, 'rejectReason')}
                    />
                  </Form.Item>
                ) : null}
              </>
            ) : null}
          </Col>
        </UserLastRow>
        <DrawerFooter className="drawer-footer-style drawerButton">
          <Form.Item className="full-row no-border">
            {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            ) : (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            )}
            {props.viewMode === 'Edit'
              ? props.visible && (
                  <SubmitButton htmlType="submit" type="primary">
                    Save
                  </SubmitButton>
                )
              : props.viewMode === 'Create'
                ? props.visible && (
                    <SubmitButton htmlType="submit" type="primary">
                      Submit
                    </SubmitButton>
                  )
                : null}

            {props.userRole === Role.Admin && resourceID ? (
              <DeleteTabDiv
                style={{ marginLeft: '20px', marginTop: '10px' }}
                className=""
                onClick={() => deleteResource(resourceID)}
              >
                <DeleteOutlined />
              </DeleteTabDiv>
            ) : null}
          </Form.Item>
        </DrawerFooter>
        {videoPlayerComponent}
      </Spin>
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    showFormData: state.formReducer.showFormData,
    customerDataList: state.notificationReducer.customerDataList,
    fileLoading: state.uploaderReducer.fileUploaderLoading
  };
}

export default connect(mapStateToProps)(ResourcesForm);
