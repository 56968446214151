import { combineReducers } from 'redux';
import customerRuducer from './reducers/customerRuducer';
import formReducer from './reducers/formReducer';
import notificationReducer from './reducers/notificationReducer';
import resourceObjectiveReducer from './reducers/resourceObjectiveReducer';
import sessionReducers from './reducers/sessionReducers';
import trackingReducer from './reducers/trackingReducer';
import uploaderReducer from './reducers/uploaderReducer';
import userMemberReducer from './reducers/userMemberReducer';
import userProgramRuducer from './reducers/userProgramRuducer';
import videoCategoryReducer from './reducers/videoCategoryReducer';

const rootReducer = combineReducers({
  userMemberData: userMemberReducer,
  trackReducer: trackingReducer,
  customerRuducer,
  programRuducer: userProgramRuducer,
  sessionReducer: sessionReducers,
  formReducer,
  notificationReducer,
  uploaderReducer,
  videoCategoryReducer,
  resourceObjectiveReducer
});

export default rootReducer;
